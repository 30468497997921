import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material'
import { downloadMediaFile } from 'api/mediaFiles'
import LinearProgressWithLabel from 'components/LinearProgressWithLabel'
import { minButtonWidth } from 'const'
import { UIModal } from 'features/UI'
import React, {
  BaseSyntheticEvent,
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { AudioFiles, AudioLanguages, AudioLanguagesOptions } from '../../models/Article.model'
import { ArticleAudioUploadItem } from '../ArticleAudioUploadItem/ArticleAudioUploadItem'

interface Props {
  isOpen: boolean
  handleClose: () => void
  title: string
  buttonText?: string
  enMediaFileId?: number | null
  esMediaFileId?: number | null
  onSubmit: (files: AudioFiles, filesToDelete: Record<AudioLanguages, boolean>) => void
  loading?: boolean
  progressBarValue?: number
}

function ModalContent({
  handleClose,
  enMediaFileId,
  esMediaFileId,
  onSubmit,
  loading,
  progressBarValue,
}: Props) {
  const [error, setError] = useState<string | null>(null)
  const [language, setLanguage] = useState('')

  const handleChangeLanguage = (event: SelectChangeEvent) => {
    setLanguage(event.target.value)
  }

  const [files, setFiles] = useState<AudioFiles>({
    [AudioLanguages.EN]: null,
    [AudioLanguages.ES]: null,
  })

  const [oldFilesUrls, setOldFilesUrls] = useState<Record<AudioLanguages, string | null>>({
    [AudioLanguages.EN]: null,
    [AudioLanguages.ES]: null,
  })

  const [filesToDelete, setFilesToDelete] = useState<Record<AudioLanguages, boolean>>({
    [AudioLanguages.EN]: false,
    [AudioLanguages.ES]: false,
  })

  const onChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
    const file = (event as unknown as BaseSyntheticEvent<File>).target.files[0]
    event.target.value = ''
    setFiles((prev) => ({
      ...prev,
      [language]: {
        file,
        src: URL.createObjectURL(file),
      },
    }))
    setError(null)
  }

  const onSubmitWrapper = () => {
    onSubmit(files, filesToDelete)
  }

  const handleRemove = (language: AudioLanguages) => {
    setFiles((prev) => ({
      ...prev,
      [language]: null,
    }))
    setOldFilesUrls((prev) => ({
      ...prev,
      [language]: null,
    }))
    setFilesToDelete((prev) => ({
      ...prev,
      [language]: true,
    }))
  }

  const inputRef = useRef<HTMLInputElement | null>(null)

  const onClickUpload = () => {
    inputRef.current?.click()
  }

  const loadMediaFiles = useCallback(async () => {
    if (enMediaFileId) {
      const url = await downloadMediaFile(enMediaFileId)
      setOldFilesUrls((prev) => ({ ...prev, [AudioLanguages.EN]: url }))
    }

    if (esMediaFileId) {
      const url = await downloadMediaFile(esMediaFileId)
      setOldFilesUrls((prev) => ({ ...prev, [AudioLanguages.ES]: url }))
    }
  }, [enMediaFileId, esMediaFileId])

  useEffect(() => {
    loadMediaFiles()
  }, [loadMediaFiles])

  return (
    <Box width="100%">
      <Stack minHeight="72px" spacing={2}>
        <FormControl sx={{ width: '50%' }} size="small">
          <InputLabel>Language</InputLabel>
          <Select value={language} label="Language" onChange={handleChangeLanguage}>
            <MenuItem value="">Select a language</MenuItem>
            {Object.keys(AudioLanguagesOptions).map((lang) => {
              const langOptions = AudioLanguagesOptions[lang as AudioLanguages]
              return (
                <MenuItem key={lang} value={lang}>
                  {langOptions.label}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>

        <input
          ref={inputRef}
          onChange={onChangeFile}
          id="audio-file"
          type="file"
          accept="audio/wav, audio/mpeg, audio/mp4, audio/ogg, audio/aacp, audio/flac"
          style={{ display: 'none' }}
        />

        <Button variant="contained" disabled={!language} onClick={onClickUpload}>
          Upload file
        </Button>

        {Object.keys(AudioLanguagesOptions).map((lang) => {
          const file = files[lang as AudioLanguages]
          const oldFilesUrl = oldFilesUrls[lang as AudioLanguages]
          const src = file?.src || oldFilesUrl

          if (!src) {
            return null
          }

          return (
            <ArticleAudioUploadItem
              key={`player-${lang}`}
              src={src}
              language={lang as AudioLanguages}
              onRemove={handleRemove}
            />
          )
        })}

        {error && (
          <Typography color="error" variant="subtitle2" fontWeight={400} mt={1}>
            {error}
          </Typography>
        )}

        {loading && (
          <Box>
            <LinearProgressWithLabel value={progressBarValue || 0} />
          </Box>
        )}
      </Stack>

      <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 5 }}>
        <Button variant="outlined" onClick={handleClose} sx={minButtonWidth} disabled={loading}>
          Close
        </Button>
        <LoadingButton
          loading={loading}
          variant="contained"
          sx={minButtonWidth}
          onClick={onSubmitWrapper}
        >
          Save
        </LoadingButton>
      </Stack>
    </Box>
  )
}

export const ArticleAudioUploadModal = (props: Props) => {
  return (
    <UIModal title={props.title} isOpen={props.isOpen} width={500} onClose={props.handleClose}>
      <ModalContent {...props} />
    </UIModal>
  )
}
