import { Button } from '@mui/material'
import { createMediaFile } from 'api/mediaFiles'
import NotificationSys from 'components/NotificationSystem'
import { useUploadAudioModal } from 'hooks/useUploadAudioModal'
import React, { Dispatch, SetStateAction } from 'react'
import { LearnArticle, Lesson } from 'types'
import { isDefined } from 'utils'
import {
  ArticleOptionsByType,
  AudioFiles,
  AudioLanguages,
  ContentType,
} from '../../models/Article.model'
import { ArticleAudioUploadModal } from '../ArticleAudioUploadModal/ArticleAudioUploadModal'

export function ArticleAudioControls({
  entityType,
  data,
  setData,
}: {
  entityType: ContentType
  data: Lesson | LearnArticle
  setData: Dispatch<SetStateAction<Lesson | LearnArticle | null>>
}) {
  const {
    mediaLoading,
    setMediaLoading,
    isOpen,
    handleOpen,
    handleClose,
    progressBarValue,
    onUploadProgress,
  } = useUploadAudioModal()

  const onSubmit = async (files: AudioFiles, filesToDelete: Record<AudioLanguages, boolean>) => {
    setMediaLoading(true)
    try {
      const { loadFunc, updateFunc } = ArticleOptionsByType[entityType]
      const entityResponse = await loadFunc({ id: Number(data.id) })
      const updateRequestData = { ...entityResponse.data }
      if (files[AudioLanguages.EN] !== null) {
        const mediaResponse = await createMediaFile(files[AudioLanguages.EN].file, onUploadProgress)
        const newMediaId = mediaResponse.data.id
        updateRequestData.enMediaFileId = newMediaId
      } else if (filesToDelete[AudioLanguages.EN]) {
        updateRequestData.enMediaFileId = null
      }
      if (files[AudioLanguages.ES] !== null) {
        const mediaResponse = await createMediaFile(files[AudioLanguages.ES].file, onUploadProgress)
        const newMediaId = mediaResponse.data.id
        updateRequestData.esMediaFileId = newMediaId
      } else if (filesToDelete[AudioLanguages.ES]) {
        updateRequestData.esMediaFileId = null
      }

      await updateFunc(updateRequestData)
      setData((previous) => {
        if (previous) {
          return {
            ...updateRequestData,
          }
        }
        return null
      })
      NotificationSys.showSuccess('Audio successfully changed')
      handleClose()
    } finally {
      setMediaLoading(false)
    }
  }

  return (
    <>
      <Button variant="outlined" onClick={handleOpen} sx={{ minWidth: 90 }}>
        {isDefined(data.enMediaFileId) || isDefined(data.esMediaFileId)
          ? 'Change Audio'
          : 'Add Audio'}
      </Button>

      <ArticleAudioUploadModal
        isOpen={isOpen}
        onSubmit={onSubmit}
        handleClose={handleClose}
        enMediaFileId={data.enMediaFileId}
        esMediaFileId={data.esMediaFileId}
        title="Upload Audio"
        loading={mediaLoading}
        progressBarValue={progressBarValue}
      />
    </>
  )
}
