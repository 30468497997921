import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import { Collapse, Stack, Typography, useMediaQuery } from '@mui/material'
import { UIStatusBadge, useCollapseContext } from 'features/UI'
import { ReactNode } from 'react'
import { Colors } from 'styles/colors'
import { useTheme } from 'themes/useTheme'

export function CourseCollapseItem({
  id,
  name,
  children,
  bannerUrl,
  status,
}: {
  id: number
  name: string | ReactNode
  children: ReactNode
  bannerUrl?: string | null
  status?: 'new' | 'retake' | 'done' | 'review'
}) {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))

  const { activeCollapseId, setActiveCollapse } = useCollapseContext()
  const isOpen = id === activeCollapseId

  const toggleOpen = () => {
    const collapseId = activeCollapseId === id ? null : id
    setActiveCollapse(collapseId)
  }

  return (
    <Stack
      sx={{
        background: 'white',
        padding: 1.5,
        borderRadius: 2,
        width: '100%',
        border: `1px solid ${Colors.PINK}`,
      }}
      style={{ marginTop: '8px', marginBottom: '8px' }}
    >
      <Stack
        direction="row"
        alignItems="center"
        onClick={toggleOpen}
        borderRadius={2}
        className="gap-8"
        sx={{ cursor: 'pointer' }}
      >
        <Stack
          direction="row"
          alignItems="center"
          flexGrow={1}
          flexWrap={matches ? 'wrap' : 'nowrap'}
          className="gap-8"
        >
          {bannerUrl && (
            <img
              style={{
                verticalAlign: 'middle',
                maxWidth: 60,
                maxHeight: 60,
              }}
              src={bannerUrl}
              alt=""
            />
          )}

          <Typography
            fontWeight={400}
            variant="h4"
            color="secondary"
            flexGrow={1}
            sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}
          >
            {name}
          </Typography>

          {status && <UIStatusBadge status={status} />}
        </Stack>

        <KeyboardArrowDown
          sx={{
            width: '2.25rem',
            height: '2.25rem',
            transform: isOpen ? 'rotate(-180deg)' : 'rotate(0)',
            transition: '0.2s',
          }}
        />
      </Stack>

      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <Stack spacing={1} p={1} mt={2} borderRadius={2} sx={{ background: '#ccc' }}>
          {children}
        </Stack>
      </Collapse>
    </Stack>
  )
}
