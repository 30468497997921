import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, Modal, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles/createTheme'
import { defaultModalStyle, defaultModalZIndex } from 'const'
import React, { ReactNode } from 'react'

const modalCloseButtonStyle = {
  position: 'absolute',
  right: 8,
  top: 8,
  color: (theme: Theme) => theme.palette.grey[500],
}

export function UIModal({
  title,
  isOpen,
  children,
  onClose,
  width = 420,
}: {
  title: string
  isOpen: boolean
  children: ReactNode
  onClose: () => void
  width?: number
}) {
  return (
    <Modal sx={defaultModalZIndex} open={isOpen} onClose={onClose}>
      {isOpen ? (
        <Box
          sx={{
            ...defaultModalStyle,
            width,
          }}
        >
          <IconButton onClick={onClose} sx={modalCloseButtonStyle}>
            <CloseIcon />
          </IconButton>

          <Typography variant="h5" mb={3}>
            {title}
          </Typography>

          {children}
        </Box>
      ) : (
        <Box />
      )}
    </Modal>
  )
}
