import { Box, Button, Grid, Stack } from '@mui/material'
import Breadcrumbs from 'components/Breadcumbs'
import React, { Dispatch, SetStateAction, useMemo } from 'react'
import { EditorMode, LearnArticle, Lesson } from 'types'
import { ArticleOptionsByType, ContentType } from '../../models/Article.model'
import { ArticleAudioControls } from '../ArticleAudioControls/ArticleAudioControls'

export function ArticleEditorToolbar({
  mode,
  onClickPreview,
  lessonId,
  lessonName,
  onClickSave,
  entityType,
  data,
  setData,
}: {
  mode: EditorMode
  entityType: ContentType
  onClickPreview: () => void
  onClickSave: () => void
  lessonId: number | string | undefined
  lessonName: string
  data: Lesson | LearnArticle
  setData: Dispatch<SetStateAction<Lesson | LearnArticle | null>>
}) {
  const path = useMemo(() => {
    return [
      {
        id: lessonId,
        href: ArticleOptionsByType[entityType].parentHref,
        text: lessonName,
      },
      {
        text: 'Text and Media Content',
      },
    ]
  }, [entityType, lessonId, lessonName])

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      rowSpacing={1}
      sx={{ width: '100%', maxWidth: 800 }}
    >
      <Grid item flexGrow={1000}>
        <Breadcrumbs path={path} />
      </Grid>
      <Grid item flexGrow={1}>
        <Stack direction="row" spacing={2}>
          <ArticleAudioControls entityType={entityType} data={data} setData={setData} />

          <Button variant="outlined" onClick={onClickPreview} sx={{ minWidth: 90 }}>
            {mode == EditorMode.Edit ? 'Preview' : 'Edit'}
          </Button>

          <Box />

          <Button
            variant="contained"
            style={{ marginLeft: 'auto' }}
            sx={{ minWidth: 90 }}
            onClick={onClickSave}
          >
            Save
          </Button>
        </Stack>
      </Grid>
    </Grid>
  )
}
