import { Box, Button, Stack, Tab, Tabs } from '@mui/material'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import {
  checkCareArticleUniqueness,
  createCareArticle,
  deleteCareArticle,
  duplicateCareArticle,
  getCareArticleById,
  getCareArticles,
  updateCareArticle,
} from 'api/careArticles'
import columnRenderers from 'components/column-renderers'
import ConfirmPublishModal from 'components/modals/ConfirmPublishModal'
import ConfirmRemoveModal from 'components/modals/ConfirmRemoveModal'
import NotificationSys from 'components/NotificationSystem'
import InfinityAgGrid from 'components/table/InfinityAgGrid'
import TableToolbar from 'components/table/TableToolbar'
import { getTitle } from 'components/tabs/TabsTitle'
import { createTagsColumn, useTagsFilter } from 'features/Tags'
import {
  columnCreatedBy,
  columnUpdatedBy,
  getActionColumn,
  RowAction,
  useDuplicateControl,
} from 'features/UI'
import { useChangeEntity } from 'hooks/useChangeEntity'
import { useGridControl } from 'hooks/useGridControl'
import { PublishAction, usePublishModalControl } from 'hooks/usePublishModalControl'
import { useRemoveModalControl } from 'hooks/useRemoveModalControl'
import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { routes } from 'routes/routes'
import { BaseTableRequest, CareArticle, CareArticleType, FilterModel } from 'types'
import { getRoute, useGetDataSource, withIdParam } from 'utils'
import { CareArticleModalCreate } from '../CareArticleModalCreate/CareArticleModalCreate'

const TabOptions = {
  [CareArticleType.Care]: {
    title: 'Care Posts',
  },
  [CareArticleType.Task]: {
    title: 'Tasks Posts',
  },
}

const TabOrder = [CareArticleType.Care, CareArticleType.Task]

export function CareArticlesPage() {
  const navigate = useNavigate()
  const { type } = useParams()

  const selectedTab = useMemo(() => {
    if (!type) return CareArticleType.Care

    return type as CareArticleType
  }, [type])

  const handleChangeSelectedTab = useCallback(
    (_: React.SyntheticEvent, newValue: CareArticleType) => {
      const route = getRoute(routes.manageCarePosts, { type: newValue })
      navigate(route)
    },
    [navigate],
  )

  const { TagFilter, tagsFilterValue } = useTagsFilter()

  const getRequest = useCallback(
    (req: BaseTableRequest) => {
      const filter: FilterModel = {
        ...(req.filter || {}),
      }
      filter.type = { type: 'text', op: 'equals', value: selectedTab }

      if (tagsFilterValue) {
        filter.tagId = tagsFilterValue
      }

      const request = { ...req, filter }

      return getCareArticles(request)
    },
    [selectedTab, tagsFilterValue],
  )

  const getDataSource = useGetDataSource(getRequest)
  const { onGridReady, gridApi } = useGridControl(getDataSource)

  const { DuplicateModal, setItem: setCareArticleToDuplicate } = useDuplicateControl<CareArticle>({
    entityName: 'Timeline Post',
    apiMethod: duplicateCareArticle,
    routeSuccess: routes.timelinePostEdit,
  })

  const { idToRemove, openDeleteModal, closeDeleteModal, removeLoading, handleConfirmRemove } =
    useRemoveModalControl({
      deleteFunc: deleteCareArticle,
      successCallback: () => gridApi?.purgeInfiniteCache(),
      warning: "Can't remove the timeline post",
    })

  const onClickEdit = useCallback(
    (id: number) => {
      navigate(withIdParam(routes.timelinePostEdit, id))
    },
    [navigate],
  )

  const {
    action,
    idToPublish,
    openPublishModal,
    closePublishModal,
    publishLoading,
    handleConfirmPublish,
  } = usePublishModalControl({
    successCallback: (_: number, action: PublishAction) => {
      const isPublished = action === PublishAction.Publish
      if (isPublished) {
        NotificationSys.showSuccess(`Article is published`)
      } else {
        NotificationSys.showSuccess(`Article is unpublished`)
      }
      gridApi?.purgeInfiniteCache()
    },
    loadFunc: getCareArticleById,
    updateFunc: updateCareArticle,
  })

  const onPublish = useCallback(
    (id: number, props: ICellRendererParams<{ isPublished: boolean }>) => {
      const isPublished = props?.data?.isPublished
      const action = isPublished ? PublishAction.Unpublish : PublishAction.Publish
      openPublishModal(id, action)
    },
    [openPublishModal],
  )

  const onDuplicateClicked = useCallback(
    (_: number, value: ICellRendererParams<CareArticle>) => {
      setCareArticleToDuplicate(value.data)
    },
    [setCareArticleToDuplicate],
  )

  const publishActionRender = useCallback(
    (props: ICellRendererParams<{ isPublished: boolean }>) => {
      const isPublished = props?.data?.isPublished
      return isPublished ? 'Unpublish' : 'Publish'
    },
    [],
  )

  const actions: RowAction[] = useMemo(() => {
    return [
      {
        name: 'Edit',
        onClick: onClickEdit,
      },
      { name: 'Delete', onClick: openDeleteModal },
      { renderName: publishActionRender, onClick: onPublish },
      { name: 'Duplicate', onClick: onDuplicateClicked },
    ]
  }, [onClickEdit, onDuplicateClicked, onPublish, openDeleteModal, publishActionRender])

  const columnDefs: ColDef[] = useMemo(() => {
    return [
      {
        headerName: 'Timeline Post Name',
        cellRenderer: columnRenderers.createLink('name', routes.timelinePostEdit),
        filter: 'agTextColumnFilter',
        colId: 'name',
        flex: 1,
      },
      { headerName: 'Linked Learn Library Article', field: 'learnArticleName', flex: 1 },
      createTagsColumn('articleTags'),
      {
        headerName: 'Creating Date',
        cellRenderer: columnRenderers.createdAt,
        minWidth: 220,
        colId: 'createdAt',
      },
      columnCreatedBy(),
      {
        headerName: 'Last Edited',
        cellRenderer: columnRenderers.updatedAt,
        minWidth: 220,
        colId: 'updatedAt',
      },
      columnUpdatedBy(),
      {
        headerName: 'Published',
        cellRenderer: columnRenderers.published,
        minWidth: 160,
        colId: 'isPublished',
      },
      { ...getActionColumn(actions) },
    ]
  }, [actions])

  const [loading, setLoading] = useState(false)
  const [onSubmit, openAddModal, isOpenAddModal, closeAddModal] = useChangeEntity({
    setLoading,
    onSuccess: (response) => {
      const id = response.id
      navigate(withIdParam(routes.timelinePostEdit, id))
    },
    requestFunc: createCareArticle,
    existedNameWarning: "Name must be unique. Can't create the timeline post with existing name",
    warning: "Can't create the timeline post",
  })

  const onSubmitWrapper = async (name: string, type: string) => {
    const success = await checkCareArticleUniqueness({ name })
    if (!success) {
      NotificationSys.showWarning('Timeline Post with such name already exists')
      return
    }

    await onSubmit({ name, type: type as CareArticleType })
  }

  return (
    <>
      <TableToolbar
        left={
          <Tabs
            value={type}
            onChange={handleChangeSelectedTab}
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons={false}
          >
            {TabOrder.map((tabType) => (
              <Tab key={tabType} value={tabType} label={getTitle(TabOptions[tabType].title)} />
            ))}
          </Tabs>
        }
      >
        <Button variant="outlined" sx={{ minWidth: 120 }} onClick={openAddModal}>
          Add Article
        </Button>
      </TableToolbar>

      <Stack flexDirection="row" mt={1} mb={2} className="gap-16">
        <Box minWidth="200px">{TagFilter}</Box>
      </Stack>

      <InfinityAgGrid pagination columnDefs={columnDefs} onGridReady={onGridReady} />

      {DuplicateModal}

      <ConfirmRemoveModal
        entityToRemove="Timeline Post"
        loading={removeLoading}
        isOpen={idToRemove !== null}
        handleConfirm={handleConfirmRemove}
        handleClose={closeDeleteModal}
      />

      <CareArticleModalCreate
        isOpen={isOpenAddModal}
        isLoading={loading}
        onClose={closeAddModal}
        onSubmit={onSubmitWrapper}
      />

      <ConfirmPublishModal
        loading={publishLoading}
        handleClose={closePublishModal}
        isOpen={idToPublish !== null}
        handleConfirm={handleConfirmPublish}
        action={action}
        entity="Timeline Post"
      />
    </>
  )
}
