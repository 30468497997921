import { Box, Button, Stack } from '@mui/material'
import { ColDef, ICellRendererParams, RowDragEvent } from 'ag-grid-community'
import {
  createLibraryTopic,
  deleteLibraryTopic,
  duplicateTopic,
  getLibraryTopics,
  updateTopicsOrder,
} from 'api/learnTopics'
import columnRenderers from 'components/column-renderers'
import ConfirmRemoveModal from 'components/modals/ConfirmRemoveModal'
import ModalWithTextAndIcon, {
  NameAndIconRequest,
} from 'components/modals/simple-modals/ModalWithTextAndIcon'
import LoadingPlaceholder from 'components/placeholders/LoadingPlaceholder'
import InfinityAgGrid from 'components/table/InfinityAgGrid'
import TableToolbar from 'components/table/TableToolbar'
import { createSummaryTagsColumn, useTagsFilterForDraggableTable } from 'features/Tags'
import { columnCreatedBy, getActionColumn, columnUpdatedBy, useDuplicateControl } from 'features/UI'
import { useChangeEntity } from 'hooks/useChangeEntity'
import { useRemoveModalControl } from 'hooks/useRemoveModalControl'
import React, { useCallback, useMemo, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'
import { LearnTopic, Option, TableResponse, Tag } from 'types'
import { withIdParam } from 'utils'

export const LearnLibraryAdminPage = () => {
  const navigate = useNavigate()
  const [topics, setTopics] = useState<LearnTopic[]>([])

  const topicsQuery = useQuery(
    ['getLibraryTopics'],
    () => getLibraryTopics({ sort: { field: 'order', sort: 'asc' } }),
    {
      onSuccess: ({ data }: TableResponse<LearnTopic>) => {
        setTopics(data.rows)
      },
    },
  )

  const { DuplicateModal, setItem: setTopicToDuplicate } = useDuplicateControl<LearnTopic>({
    entityName: 'Topic',
    apiMethod: duplicateTopic,
    routeSuccess: routes.topicEdit,
  })

  const { idToRemove, openDeleteModal, closeDeleteModal, removeLoading, handleConfirmRemove } =
    useRemoveModalControl({
      deleteFunc: deleteLibraryTopic,
      successCallback: () => {
        setTopics((prev) => prev.filter((topic) => topic.id !== idToRemove))
      },
      warning: "Can't remove the learn library topic",
    })

  const onClickEdit = useCallback(
    (id: number) => {
      navigate(withIdParam(routes.topicEdit, id))
    },
    [navigate],
  )

  const handleDuplicateClick = useCallback(
    (id: number, value: ICellRendererParams<LearnTopic>) => {
      setTopicToDuplicate(value.data)
    },
    [setTopicToDuplicate],
  )

  const actions = useMemo(() => {
    return [
      {
        name: 'Edit',
        onClick: onClickEdit,
      },
      { name: 'Delete', onClick: openDeleteModal },
      { name: 'Duplicate', onClick: handleDuplicateClick },
    ]
  }, [handleDuplicateClick, onClickEdit, openDeleteModal])

  const filterTopicsByTags = useCallback(
    (tags: Option[]) => {
      return topics.filter((topic) => {
        return topic.articles.some((article) => {
          return article.tags.some((tag: Tag) => {
            return tags.some((selectedTag) => selectedTag.value === tag.id)
          })
        })
      })
    },
    [topics],
  )

  const { TagFilter, rowData, isDraggable } = useTagsFilterForDraggableTable<LearnTopic>(
    topics,
    'tags',
    filterTopicsByTags,
  )

  const columnDefs: ColDef[] = useMemo(() => {
    return [
      {
        headerName: 'Topic Name',
        rowDrag: isDraggable,
        cellRenderer: columnRenderers.createLink('name', routes.topicEdit),
        filter: 'agTextColumnFilter',
        colId: 'name',
        flex: 1,
      },
      { headerName: 'Number of Articles', field: 'numberOfArticles' },
      createSummaryTagsColumn('articles'),
      {
        headerName: 'Creation Date',
        cellRenderer: columnRenderers.createdAt,
      },
      columnCreatedBy(),
      {
        headerName: 'Last Edited Date',
        cellRenderer: columnRenderers.updatedAt,
      },
      columnUpdatedBy(),
      { ...getActionColumn(actions) },
    ]
  }, [actions, isDraggable])

  const [loading, setLoading] = useState(false)

  const [onSubmit, openAddModal, isOpenAddModal, closeAddModal] = useChangeEntity({
    setLoading,
    onSuccess: (response) => {
      navigate(withIdParam(routes.topicEdit, response.id))
    },
    requestFunc: createLibraryTopic,
    existedNameWarning:
      "Name must be unique. Can't create the learn library topic with existing name",
    warning: "Can't create the learn library topic",
  })

  const updateTopicsOrderMutation = useMutation(updateTopicsOrder)

  const handleRowDragEnd = useCallback(
    (e: RowDragEvent<LearnTopic>) => {
      const orderedIds = e.api
        .getRenderedNodes()
        .map((node) => (node.data ? node.data.id : null))
        .filter((id): id is number => id !== null)

      updateTopicsOrderMutation.mutate(
        { ids: orderedIds },
        {
          onSuccess: () => {
            const orderedItems = [...topics].sort(
              (a, b) => orderedIds.indexOf(a.id) - orderedIds.indexOf(b.id),
            )
            setTopics(orderedItems)
          },
          onError: () => {
            setTopics([...topics])
          },
        },
      )
    },
    [topics, updateTopicsOrderMutation],
  )

  const handleCreate = (values: NameAndIconRequest) => {
    onSubmit({ name: values.name, bannerMediaFileId: values.icon })
  }

  return (
    <>
      <TableToolbar title="Learn Library Overview">
        <Button variant="outlined" sx={{ minWidth: 120 }} onClick={openAddModal}>
          Add Learn Topic
        </Button>
      </TableToolbar>

      {topicsQuery.isLoading ? (
        <LoadingPlaceholder />
      ) : (
        <>
          <Stack flexDirection="row" mt={1} mb={2} className="gap-16">
            <Box minWidth="200px">{TagFilter}</Box>
          </Stack>

          <InfinityAgGrid
            columnDefs={columnDefs}
            rowDragManaged={isDraggable}
            animateRows
            rowDragEntireRow
            rowModelType="clientSide"
            rowData={rowData}
            onRowDragEnd={handleRowDragEnd}
            floatingFiltersHeight={0}
          />
        </>
      )}

      <ConfirmRemoveModal
        entityToRemove="Topic"
        loading={removeLoading}
        isOpen={idToRemove !== null}
        handleConfirm={handleConfirmRemove}
        handleClose={closeDeleteModal}
      />

      <ModalWithTextAndIcon
        title="Create Topic"
        label="Topic name"
        buttonText="Create"
        isOpen={isOpenAddModal}
        handleClose={closeAddModal}
        loading={loading}
        setLoading={setLoading}
        onSubmit={handleCreate}
      />

      {DuplicateModal}
    </>
  )
}
