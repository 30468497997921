import { ICellRendererParams } from 'ag-grid-community'
import { PLACEHOLDER } from 'components/column-renderers'
import { UserUpdater } from 'types'

export const columnCreatedOrUpdatedBy = (propName: string, label: string) => {
  return {
    headerName: label,
    flex: 1,
    minWidth: 300,
    cellRenderer: (props: ICellRendererParams) => {
      if (!props.data) return PLACEHOLDER

      const updater: UserUpdater = props.data[propName]
      if (!updater) return PLACEHOLDER

      return <div>{`${updater.firstname} ${updater.lastname} / ${updater.email}`}</div>
    },
  }
}

export const columnCreatedBy = () => {
  return columnCreatedOrUpdatedBy('creator', 'Created by')
}

export const columnUpdatedBy = () => {
  return columnCreatedOrUpdatedBy('updater', 'Updated By')
}

export const columnCreatedOrUpdatedByCSV = (propName: string, label: string) => {
  return {
    label,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: (data: any) => {
      if (!data) return PLACEHOLDER

      const updater: UserUpdater = data[propName]
      if (!updater) return PLACEHOLDER

      return `${updater.firstname} ${updater.lastname} / ${updater.email}`
    },
  }
}

export const csvColumnCreatedBy = () => {
  return columnCreatedOrUpdatedByCSV('creator', 'Created by')
}

export const csvColumnUpdatedBy = () => {
  return columnCreatedOrUpdatedByCSV('updater', 'Updated by')
}
