import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton } from '@mui/material'
import AudioPlayer from 'components/audio/AudioPlayer'
import ConfirmRemoveModal from 'components/modals/ConfirmRemoveModal'
import React, { useCallback, useState } from 'react'
import { AudioLanguages } from '../../models/Article.model'
import * as Styled from './ArticleAudioUploadItem.styled'

export function ArticleAudioUploadItem({
  src,
  language,
  onRemove,
}: {
  src: string
  language: AudioLanguages
  onRemove: (language: AudioLanguages) => void
}) {
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)

  const handleOpenRemoveModal = () => {
    setIsRemoveModalOpen(true)
  }

  const handleCloseRemoveModal = () => {
    setIsRemoveModalOpen(false)
  }

  const handleRemove = useCallback(() => {
    onRemove(language)
  }, [language, onRemove])

  return (
    <Styled.Container>
      <Styled.LangContainer>{language}</Styled.LangContainer>

      <Styled.PlayerContainer>
        <AudioPlayer src={src} key={src} />
      </Styled.PlayerContainer>

      <IconButton aria-label="delete" onClick={handleOpenRemoveModal}>
        <DeleteIcon />
      </IconButton>

      <ConfirmRemoveModal
        isOpen={isRemoveModalOpen}
        entityToRemove="Audio"
        loading={false}
        handleClose={handleCloseRemoveModal}
        handleConfirm={handleRemove}
      />
    </Styled.Container>
  )
}
